/* Styles globaux */
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("Mukta-Bold"),
  url("./fonts/Mukta-Bold.ttf") format("truetype");
}

